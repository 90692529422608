(function () {
	'use strict';

	angular
		.module('sidebar')
		.directive('sidebarResize', ['$rootScope', 'utilities', sidebarResize]);

	function sidebarResize($rootScope, utilities) {
		return function (scope, element, attrs) {
			//Watch for the sidebar to transition. This way we resize after it is hidden or shown
			if (transitionSupported()) {
				element.on(
					'webkitTransitionEnd msTransitionEnd oTransitionEnd transitionend',
					function (event) {
						//check to make sure we are on the sidebar element here
						if (element.is(event.target)) {
							//Broadcast resize imediately so if anything get's triggered we can make that happen
							$rootScope.$broadcast('resize', 'sidebar');
							if (
								utilities.getWindowDimensions().windowWidth >
								1200
							) {
								utilities.resizeCalendar(50);
							}
						}
					}
				);
				//Also watch for a transition cancel event if the element is hidden before the transition is fully done
				element.on('transitioncancel', function (event) {
					//check to make sure we are on the sidebar element here
					if (element.is(event.target)) {
						//Broadcast resize imediately so if anything get's triggered we can make that happen
						$rootScope.$broadcast('resize', 'sidebar');
						if (
							utilities.getWindowDimensions().windowWidth > 1200
						) {
							utilities.resizeCalendar(50);
						}
					}
				});
			} else {
				//Enable watcher if transitions aren't supported. This way we can resize properly on older browsers
				scope.$watch('sidebar.show', function (newValue, oldValue) {
					if (newValue !== oldValue) {
						//Broadcast resize imediately so if anything get's triggered we can make that happen
						$rootScope.$broadcast('resize', 'sidebar');
						utilities.resizeCalendar(350);
					}
				});
			}
		};

		//Feature detection for transitions. We need this specifically for IE9
		function transitionSupported() {
			var s = document.createElement('p').style,
				supportsTransitions =
					'transition' in s ||
					'WebkitTransition' in s ||
					'MozTransition' in s ||
					'msTransition' in s ||
					'OTransition' in s;
			return supportsTransitions;
		}
	}
})();
